import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { format, parse } from "date-fns";
import { osName } from "react-device-detect";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { Button, Modal } from "@/components/Common";
import { moeda } from "@/formatters";
import { useTce } from "@/hooks";
import { toast } from "react-toastify";
import DocumentoTCE from "./Documento";

import ModalAceite from "./ModalAceite";
import ModalRecuse from "./ModalRecuse";
import ModalAddCoordenador from "./ModalAddCoordenador";
import ModalAddAssinante from "./ModalAddAssinante";
import ModalSolicitarDesligamento from "./ModalSolicitarDesligamento";
import Coordenadores from "./Coordenadores";
import AssinantesEscola from "./AssinantesEscola";
import { checkMinhaVez } from "./check-minha-vez";

import EventEmitter from "@/event-emitter";

import {
  Container,
  Topo,
  Controles,
  Sobre,
  SobreTitulo,
  SobreSection,
  SolicitacoesDesligamento,
} from "./styles";

function TCE({ tce, history }) {
  const { data: user } = useSelector(state => state.auth);
  const { reprovarDesligamento, aprovarDesligamento, loading } = useTce();

  const { contratado, contratante } = tce.contrato;
  const [modalAceite, setModalAceite] = useState(false);
  const [modalRecuse, setModalRecuse] = useState(false);
  // const [modalAddCoordenador, setModalAddCoordenador] = useState(false);
  const [modalAddAssinante, setModalAddAssinante] = useState(false);
  const [modalSolicitarDesligamento, setModalSolicitarDesligamento] = useState(
    false
  );

  useEffect(() => {
    EventEmitter.subscribe("assinante-removed", event =>
      window.location.reload(true)
    );
  }, []);

  const { tipo, empresa } = user;
  // const { status, contrato } = tce;

  const minhaVez = checkMinhaVez(tce, user);

  //verificar divergencia de assinantes
  const assinantesEscola = tce.assinantes_escola;

  const { temCasoApproved, temCasoDeleted } = assinantesEscola.reduce(
      (accumulator, assinante) => {
          if (assinante.data_assinatura) {
              accumulator.temCasoApproved = true;
          }
          if (assinante.deleted_at) {
              accumulator.temCasoDeleted = true;
          }

          return accumulator;
      },
      { temCasoApproved: false, temCasoDeleted: false }
  );

  const divergencia = temCasoApproved && temCasoDeleted;
  //fim da verificacao

  const showCoordenadores =
    (minhaVez === 0) &&
    tce.aceite_integrador !== null &&
    user.tipo === "funcionario" &&
    user.empresa.id === tce.contrato.contratado_escola.id;

  const showAtualizarContrato = user.tipo === "administrador";

  function renderButtonAceito() {
    // se for a escola, não exibir botão de aceite, apenas a opção de adicionar outros assinantes
    if (empresa && empresa.is_escola && user.tipo !== "aluno") {
      return <></>;
    }
    return (
      <Button
        variant="tangerine"
        disabled={minhaVez !== 0 || user.tipo === "franqueado"}
        onClick={() => setModalAceite(true)}
      >
        Li, concordo e aceito
      </Button>
    );
  }

  //no caso de divergencia dos assinantes da escola, exibir pro adm um botao pra aprovar
  function renderButtonAprovar() {
    if (minhaVez === 0 && divergencia && user.rootProfile && user.rootProfile.tipo === 'administrador') {
      return (
        <Button
          variant="tangerine"
          onClick={() => setModalAceite(true)}
        >
          Aprovar contrato
        </Button>
      );
    }
  }

  function renderButtonReprovar() {
    return (
      <Button
        disabled={minhaVez !== 0 || user.tipo === "franqueado"}
        onClick={() => {
          setModalRecuse(true);
        }}
      >
        Reprovar este contrato
      </Button>
    );
  }

  // function renderButtonAddCoordenador() {
  //   return (
  //     <Button
  //       disabled={minhaVez !== 0 || user.tipo === "franqueado"}
  //       variant="cyan"
  //       onClick={() => setModalAddCoordenador(true)}
  //     >
  //       Adicionar outro assinante
  //     </Button>
  //   );
  // }

  function renderButtonAddAssinante() {
    return (
      <Button
        disabled={minhaVez !== 0 || user.tipo === "franqueado"}
        variant="cyan"
        onClick={() => setModalAddAssinante(true)}
      >
        Adicionar outro assinante
      </Button>
    );
  }

  async function AtualizarContrato(data = {}) {
    const endpoint = `tces/atualizar/${tce.id}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));

    if (response.success) {
      toastify("Contrato atualizado!");
      window.location.reload(true);
    }
  }

  function renderButtonAtualizar() {
    return (
      <Button variant="cyan" onClick={() => AtualizarContrato()}>
        Atualizar Contrato
      </Button>
    );
  }

  function renderDownloadTCEButton() {
    const downloadUrl = `https://www.integrador.app/rest/files/tce-pdf/${btoa(
      tce.id
    )}`;

    return (
      <Button to={downloadUrl} target="_blank" externalLink download="tce">
        <FaDownload size={13} /> Contrato
      </Button>
    );
  }

  function renderDownloadSeguroButton() {
    const downloadUrl = `https://www.integrador.app/rest/files/seguro-pdf/${btoa(
      tce.id
    )}`;

    return (
      <Button to={downloadUrl} target="_blank" externalLink download="seguro">
        <FaDownload size={13} /> Seguro - Condições
      </Button>
    );
  }

  function renderApoliceSeguroButton() {
    const downloadUrl = `https://www.integrador.app/rest/files/apolice-pdf/${btoa(
      tce.id
    )}`;

    return (
      <Button to={downloadUrl} target="_blank" externalLink download="seguro">
        <FaDownload size={13} /> Seguro - Apólice
      </Button>
    );
  }

  function handleReprovarDesligamento(solicitacao) {
    const { id } = solicitacao;
    reprovarDesligamento(id);
  }

  function handleAprovarDesligamento(solicitacao) {
    const { id } = solicitacao;
    aprovarDesligamento(id);
  }

  function renderSolicitacaoDesligamentoControle() {
    const { status, solicitacoes_desligamento } = tce;
    if (status.descricao !== "Contrato Ativo") return <></>;

    if (!solicitacoes_desligamento) return <></>;

    const solicitacoesPendentes = solicitacoes_desligamento.filter(
      ({ status }) => status === "pendente"
    );

    if (
      solicitacoesPendentes.length === 0 &&
      user.tipo !== "administrador" &&
      user.tipo !== "franqueado"
    ) {
      return (
        <Button
          variant="magenta"
          onClick={() => setModalSolicitarDesligamento(true)}
        >
          Solicitar desligamento
        </Button>
      );
    }

    if (solicitacoesPendentes.length > 0) {
      const solicitacao = solicitacoesPendentes[0];
      const date = parse(
        solicitacao.created_at,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      const data = format(date, "dd/MM/yyyy 'às' HH:mm");

      function getSolicitanteAmigavel(string) {
        switch (string) {
          case "contratado_escola":
            return "pela escola do aluno contratado";
          case "contratante":
            return "pela empresa contratante";
          case "contratado":
            return "pelo aluno contratado";
          case "integrador":
            return "pelo Integrador.app";
        }
      }

      return (
        <SolicitacoesDesligamento>
          <div className="main">
            <h4>Solicitação de desligamento</h4>
            <p>
              Solicitado {getSolicitanteAmigavel(solicitacao.solicitante)} em{" "}
              {data}
            </p>
          </div>
          {user.tipo === "administrador" && (
            <div className="action">
              <Button
                variant="magenta"
                onClick={() => handleReprovarDesligamento(solicitacao)}
                loading={loading || undefined}
              >
                Reprovar desligamento
              </Button>
              <Button
                variant="cyan"
                onClick={() => handleAprovarDesligamento(solicitacao)}
                loading={loading || undefined}
              >
                Aprovar desligamento
              </Button>
            </div>
          )}
        </SolicitacoesDesligamento>
      );
    }
  }

  function renderAtividades() {
    const { atividades } = tce.contrato.vaga;
    return atividades.length ? (
      atividades.map((atividade, index) => {
        return <li key={index}>{atividade}</li>;
      })
    ) : (
      <li>Não definidas</li>
    );
  }

  function renderBeneficios() {
    const { beneficios } = tce.contrato.vaga;
    return beneficios.length ? (
      beneficios.map((beneficio, index) => {
        const beneficioValor =
          beneficio.valor > 0 ? (
            <span className="beneficio-valor"> {moeda(beneficio.valor)}</span>
          ) : (
            <></>
          );

        return (
          <li key={index}>
            {beneficio.descricao}
            {beneficioValor}
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );
  }

  function handleOnAceiteComplete() {
    let location;
    if (["funcionario", "aluno"].includes(tipo)) {
      location = {
        pathname: "/perfil",
        state: { tab: "TCEs" },
      };
    } else if (tipo === "administrador") {
      location = {
        pathname: "/tces/pendente-contratante",
      };
    }

    history.push(location);
  }

  return (
    <>
      {tce && (
        <Container>
          <Topo>
            <h3 className="nome">{contratado.nome}</h3>
            <h5 className="empresa">{contratante.nome_exibicao}</h5>
            <h5 className="status">{tce.status.descricao}</h5>
          </Topo>

          {!["android"].includes(osName.toLowerCase()) && (
            <DocumentoTCE tceId={tce.id} />
          )}

          {/* {showCoordenadores && <Coordenadores tce={tce} />} */}
          {showCoordenadores && <AssinantesEscola tce={tce} />}

          <Controles>
            {renderButtonAceito()}
            {renderButtonAprovar()}
            {showAtualizarContrato && <>{renderButtonAtualizar()}</>}
            {/* {showCoordenadores && <>{renderButtonAddCoordenador()}</>} */}
            {showCoordenadores && <>{renderButtonAddAssinante()}</>}
            {/* {tipo !== "administrador" && renderButtonReprovar()} */}
            {renderButtonReprovar()}

            <div className="actions">
              {renderDownloadTCEButton()}
              {tce.aceite_contratado_escola !== null && (
                <>
                  {renderDownloadSeguroButton()}
                  {renderApoliceSeguroButton()}
                </>
              )}
            </div>
          </Controles>

          <Sobre>
            <SobreTitulo>
              <h2>Sobre a vaga</h2>
            </SobreTitulo>
            <SobreSection>
              <h4>Descrição</h4>
              <p>{tce.contrato.vaga.descricao}</p>
            </SobreSection>
            <SobreSection>
              <h4>Carga horária semanal</h4>
              <p>{tce.contrato.vaga.carga_horaria_semanal.horas} horas</p>
            </SobreSection>
            <SobreSection>
              <h4>Atividades</h4>
              <ul>{renderAtividades()}</ul>
            </SobreSection>
            <SobreSection>
              <h4>Benefícios</h4>
              <ul>{renderBeneficios()}</ul>
            </SobreSection>
          </Sobre>

          {renderSolicitacaoDesligamentoControle()}

          <Modal open={modalAceite} onClose={() => setModalAceite(false)}>
            <ModalAceite
              onAceiteComplete={handleOnAceiteComplete}
              tce={tce}
              onClose={() => setModalAceite(false)}
            />
          </Modal>

          <Modal open={modalRecuse} onClose={() => setModalRecuse(false)}>
            <ModalRecuse tce={tce} onClose={() => setModalRecuse(false)} />
          </Modal>

          {/* <Modal
            open={modalAddCoordenador}
            onClose={() => setModalAddCoordenador(false)}
          >
            <ModalAddCoordenador
              tceId={tce.id}
              onClose={() => setModalAddCoordenador(false)}
              onComplete={() => {
                window.location.reload(false);
              }}
            />
          </Modal> */}

          <Modal
            open={modalAddAssinante}
            onClose={() => setModalAddAssinante(false)}
          >
            <ModalAddAssinante
              tceId={tce.id}
              onClose={() => setModalAddAssinante(false)}
              onComplete={() => {
                window.location.reload(false);
              }}
            />
          </Modal>

          <Modal
            open={modalSolicitarDesligamento}
            onClose={() => setModalSolicitarDesligamento(false)}
          >
            <ModalSolicitarDesligamento
              tce={tce}
              onClose={() => setModalSolicitarDesligamento(false)}
            />
          </Modal>
        </Container>
      )}
    </>
  );
}

export default withRouter(TCE);
